<svelte:head>
    <title>babapk 공지사항 링크</title>
</svelte:head>


<div class="main_data table-outline">
  <div class="table-head">
    <div class="table-linktext">공지사항 들어가기(구글 문서 링크)</div>
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/spreadsheets/d/19a-_44yVj04BnlUK6QQY9U3jXtlDAgOmdHCgdis1toI/"
      ><div class="table-contents">
        <div class="table-linktext">후원 내역 및 후원 랭킹 보기</div>
      </div></a
    >
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/document/d/12EBBA8c9K-KzaE-cC6QvRb8vOB07V9Mi90D44-dzOPw/"
    >
      <div class="table-contents">
        <div class="table-linktext">인게임방 방 안내</div>
      </div></a
    >
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/document/d/1b-vnUyLaDrSnSMouTOoFAYz5K3lq8hS-kzjh_R-JuD8/"
      ><div class="table-contents">
        <div class="table-linktext">월별 랭킹 시스템 안내</div>
      </div></a
    >
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/document/d/1RBDp8SzVp-epjIdekWOWPYt0f6XBpreVbQnPD1qv3-o/"
      ><div class="table-contents">
        <div class="table-linktext">미니 대회 운영 안내</div>
      </div></a
    >
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/document/d/1m50GKaWyszyBSslQn3348Fz1CAp55DFbKNwNyj5dLnw/"
      ><div class="table-contents">
        <div class="table-linktext">내부 랭킹전 안내</div>
      </div></a
    >
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/document/d/1_4E8_YqotTmRaVcbthmYuCnQbLKTwhSRPJ8rGk_Nybc/"
      ><div class="table-contents">
        <div class="table-linktext">무기의 등급 분류 안내</div>
      </div></a
    >
  </div>
</div>

<style>
  .table-contents,
  .table-head {
    width: 100%;
    height: 45px;
  }
  .table-linktext {
    margin-left: 25px;
  }
  .table-contents:hover {
text-decoration: underline;

  }

</style>
