<svelte:head>
    <title>mpk 공지사항 링크</title>
</svelte:head>


<div class="main_data table-outline">
  <div class="table-head">
    <div class="table-linktext">공지사항 들어가기(구글 문서 링크)</div>
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/spreadsheets/d/18jJ3WYsWZEdV8oXeJYt1-aMrACN1IHsFnCV3-gir5pk/"
      ><div class="table-contents">
        <div class="table-linktext">후원 내역 및 후원 랭킹 보기</div>
      </div></a
    >
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/document/d/12jpQH4QP7QyPRhLPuXJSuU3lJdA_3SzCE5dBYl2jYZg/"
    >
      <div class="table-contents">
        <div class="table-linktext">듀얼 규칙 안내</div>
      </div></a
    >
  </div>
  <div class="table-contents-wrapper">
    <a
      href="https://docs.google.com/document/d/1Wj31FLkeFLZp-N2Bohkj_ZKoUrJU5LXkUABH7x9zxC8/"
      ><div class="table-contents">
        <div class="table-linktext">대회 및 랭킹 시스템 안내</div>
      </div></a
    >
  </div>
</div>

<style>
  .table-contents,
  .table-head {
    width: 100%;
    height: 45px;
  }
  .table-linktext {
    margin-left: 25px;
  }
  .table-contents:hover {
text-decoration: underline;

  }

</style>
